<template>
  <v-menu
    offset-y
    v-if="batchConfig"
    :disabled="!listHasSelected"
    :style="{ zIndex: $zIndexConfig.listInfo }"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        small
        class="mx-2"
        :disabled="!listHasSelected"
      >
        {{ 'action.batch'|t }}
      </v-btn>
    </template>

    <v-list dense>
      <component
        v-for="(config, name) in batchConfig"
        :is="getBatchComponent(name, config)"
        :key="`batch-${name}`"
        :list-key="listKey"
        :config="config"
      ></component>
    </v-list>
  </v-menu>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    listKey: String,
  },
  methods: {
    getBatchComponent(name, config) {
      if(name === 'delete') return 'batchDelete'
      if(config.component) return config.component
      return 'baseBatch'
    },
  },
  computed: {
    listHasSelected() {
      return this.$store.getters[`list/${this.listKey}/hasSelected`]
    },
    batchConfig() {
      return this.$store.getters[`list/${this.listKey}/batchConfig`]
    },
  },
  components: {
    baseBatch: () => import('@/components/list/listToolbar/batch/base.vue'),
    batchDelete: () => import('@/components/list/listToolbar/batch/delete.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>